<template>
	<canvas v-visible="drawPage" v-bind="canvasAttrs"></canvas>
</template>

<script>
import DocumentVersionsModuleGuard from '@/mixins/ModulesGuards/Documents/DocumentVersionsModuleGuard'
import PDFPreview from '@/helpers/PDFPreview'

export default {
	name: 'PDFPage',
	directives: {
		visible: PDFPreview.directives.visible
	},
	mixins: [DocumentVersionsModuleGuard],
	props: {
		page: {
			required: true,
			type: Object // instance of PDFPageProxy returned from pdf.getPage
		},
		scale: {
			required: true,
			type: Number
		},
		optimalScale: {
			required: false,
			type: Number,
			default: 1
		},
		isPageFocused: {
			required: false,
			type: Boolean,
			default: false
		},
		isElementFocused: {
			required: false,
			type: Boolean,
			default: false
		}
	},
	computed: {
		actualSizeViewport: function () {
			return this.viewport.clone({ scale: this.scale })
		},
		canvasStyle: function () {
			const { width: actualSizeWidth, height: actualSizeHeight } = this.actualSizeViewport
			const [pixelWidth, pixelHeight] = [actualSizeWidth, actualSizeHeight].map(dim => Math.ceil(dim / PDFPreview.constants.PIXEL_RATIO))
			return `width: ${pixelWidth}px; height: ${pixelHeight}px;`
		},
		canvasAttrs: function () {
			let { width, height } = this.viewport
			;[width, height] = [width, height].map(dim => Math.ceil(dim))
			// should enforce width and height to 595*842px
			const style = this.canvasStyle
			return {
				width,
				height,
				style,
				class: 'pdf-page box-shadow'
			}
		},
		pageNumber: function () {
			return this.page.pageNumber
		},
		viewport: function () {
			return this.page.getViewport({ scale: 2.9 * this.optimalScale })
		}
	},
	watch: {
		scale: function () {
			this.updateVisibility()
		},
		page: function (_newPage, oldPage) {
			this.destroyPage(oldPage)
		},
		isElementFocused: function (isElementFocused) {
			if (isElementFocused) {
				this.focusPage()
			}
		}
	},
	created: function () {
		// PDFPageProxy#getViewport
		// https://mozilla.github.io/pdf.js/api/draft/PDFPageProxy.html#getViewport
	},
	beforeDestroy: function () {
		this.destroyPage(this.page)
	},
	methods: {
	 	isCanvasTransparent () {
			const canvas = this.$el
			const canvasContext = canvas.getContext('2d')
			const imageData=canvasContext.getImageData(0, 0, canvas.offsetWidth, canvas.offsetHeight);
			for (let i=0; i<imageData.data.length; i+=4) {
				if (imageData.data[i+3] !== 0) {
					return false
				}
			}
			return true
		},
		focusPage () {
			if (this.isPageFocused) {
				return
			}
			this.$emit('page-focus', this.pageNumber)
		},
		drawPage () {
			if (this.renderTask) {
				return
			}
			const { viewport } = this
			const canvasContext = this.$el.getContext('2d')
			const renderContext = { canvasContext, viewport }
			// PDFPageProxy#render
			// https://mozilla.github.io/pdf.js/api/draft/PDFPageProxy.html#render
			this.renderTask = this.page.render(renderContext)
			this.renderTask.promise
				.then(() => {
					let isTransparent = this.isCanvasTransparent()
					if (isTransparent) {
						this.renderTask = null
						this.drawPage()
					} else {
							this.$emit('page-rendered', {
								page: this.page,
								text: `Rendered page ${this.pageNumber}`
						})
					}
				
				})
				.catch(response => {
					this.destroyRenderTask()
					this.$emit('page-errored', {
						response,
						page: this.page,
						text: `Failed to render page ${this.pageNumber}`
					})
				})
		},
		updateVisibility () {
			this.$parent.$emit('update-visibility')
		},
		destroyPage (page) {
			// PDFPageProxy#_destroy
			// https://mozilla.github.io/pdf.js/api/draft/PDFPageProxy.html#_destroy
			if (page) {
				page._destroy()
			}
			this.destroyRenderTask()
		},
		destroyRenderTask () {
			if (!this.renderTask) {
				return
			}
			// RenderTask#cancel
			// https://mozilla.github.io/pdf.js/api/draft/RenderTask.html
			this.renderTask.cancel()
			delete this.renderTask
		}
	}
}
</script>
<style>
.pdf-page {
	display: block;
	margin: 0 auto;
}
</style>
